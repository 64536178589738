/*
 * src/components/Navigation/Mobile.js
 */

import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import child components for MobileNavigation
import Navigation from "../Navigation";
import Language from "../Language";

// Import styling for Navigation component
import "./Navigation.css";

export default function MobileNavigation() {

    // Spawn instance of translation functions
    const { t } = useTranslation();

    // Create and handle state for displaying mobile menu
    const [isActive, setActive] = useState(false);
    const handleToggle = () => {
        setActive(!isActive);
    };

    return (
        <div className={`mobile-nav show-sm ${isActive ? "active" : ""}`}>
            <Link className="nav-button" to="#">
                <div className="button open" onClick={handleToggle}></div>
            </Link>
            <div className="container">
                <Link className="nav-button" to="#">
                    <div className="button close" onClick={handleToggle}></div>
                </Link>
                <Navigation handleClose={handleToggle} />
                <div className="columns">
                    <div className="col-6">
                        <ul className="sub-nav">
                            <li className="nav-item">
                                <NavLink to="/" onClick={handleToggle}>
                                    {t("nav.cookies")}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/" onClick={handleToggle}>
                                    {t("nav.terms")}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <ul className="sub-nav">
                            <li className="nav-item">
                                <NavLink to="/" onClick={handleToggle}>
                                    {t("nav.privacy")}
                                </NavLink>
                            </li>
                            <li className="nav-item element-select">
                                <Language />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
