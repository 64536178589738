/*
 * src/components/Press/index.js
 */

import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

// Import styling for Tech component
import "./Press.css";

// Import logos for publications of Press section
import adLogo from "../../assets/img/press/logo-ad.png";
import architectLogo from "../../assets/img/press/logo-architectenweb.png";
import architonLogo from "../../assets/img/press/logo-architonic.png";
import blydeLogo from "../../assets/img/press/logo-blyde.png";
import brainLogo from "../../assets/img/press/logo-brainport.png";
import distrifoodLogo from "../../assets/img/press/logo-distrifood.png";
import ioLogo from "../../assets/img/press/logo-io.png";
import kunstLogo from "../../assets/img/press/logo-kunstgebouw.png";
import npoLogo from "../../assets/img/press/logo-npo.png";
import rtlLogo from "../../assets/img/press/logo-rtl.png";
import vproLogo from "../../assets/img/press/logo-vpro.png";

export default function Press() {

    // Spawn instance of translation functions
    const { t } = useTranslation();

    return (
        <section id="press">
            <div className="container grid-md">
                <div className="columns">
                    <div className="column type-title">
                        <h2>Press</h2>
                        <p>Media attention</p>
                    </div>
                    <div className="column type-blocks">
                        <ul className="blocks">
                            <li>
                                <img src={adLogo} alt="algemeen dagblad logo" />
                                <Link to="https://www.ad.nl/tech/een-snoepautomaat-waarbij-je-met-data-betaalt-en-een-robothand-om-oma-te-knuffelen-wil-je-dat-wel~a798e1df/" target="_blank">
                                    <button className="btn btn-secondary">{t("c.press.button")}</button>
                                </Link>
                            </li>
                            <li>
                                <img src={vproLogo} alt="vpro logo" />
                                <Link to="https://www.vpro.nl/festivals/ddw/lees/2022/tien-tips-ddw-2022.html" target="_blank">
                                    <button className="btn btn-secondary">{t("c.press.button")}</button>
                                </Link>
                            </li>
                            <li>
                                <img src={rtlLogo} alt="rtl logo" />
                                <Link to="https://www.rtlnieuws.nl/economie/artikel/5342074/dutch-design-week-gezondheidszorg-duurzaam" target="_blank">
                                    <button className="btn btn-secondary">{t("c.press.button")}</button>
                                </Link>
                            </li>
                            <li>
                                <img src={ioLogo} alt="innovations origins logo" />
                                <Link to="https://innovationorigins.com/nl/stap-in-de-wereld-van-2050-tijdens-de-dutch-design-week/" target="_blank">
                                    <button className="btn btn-secondary">{t("c.press.button")}</button>
                                </Link>
                            </li>
                            <li>
                                <img src={kunstLogo} alt="kunstgebouw logo" />
                                <Link to="https://www.youtube.com/watch?v=EoNZrdQEIiQ&t=126s&ab_channel=kunstgebouw" target="_blank">
                                    <button className="btn btn-secondary">{t("c.press.button")}</button>
                                </Link>
                            </li>
                            <li>
                                <img src={brainLogo} alt="brainport eindhoven logo" />
                                <Link to="https://brainporteindhoven.com/nl/nieuws/ddw-tip3-embassy-of-food" target="_blank">
                                    <button className="btn btn-secondary">{t("c.press.button")}</button>
                                </Link>
                            </li>
                            <li>
                                <img src={architectLogo} alt="architectenweb logo" />
                                <Link to="https://architectenweb.nl/nieuws/artikel.aspx?ID=53532" target="_blank">
                                    <button className="btn btn-secondary">{t("c.press.button")}</button>
                                </Link>
                            </li>
                            <li>
                                <img src={architonLogo} alt="architonic logo" />
                                <Link to="https://www.architonic.com/fr/story/emma-moore-successful-mission-at-dutch-design-week-2022/20287751" target="_blank">
                                    <button className="btn btn-secondary">{t("c.press.button")}</button>
                                </Link>
                            </li>
                            <li>
                                <img src={distrifoodLogo} alt="distrifood logo" />
                                <Link to="https://www.distrifood.nl/161897/toekomst-voorspellen-dit-is-de-supermarkt-in-het-jaar-2050" target="_blank">
                                    <button className="btn btn-secondary">{t("c.press.button")}</button>
                                </Link>
                            </li>
                            <li>
                                <img src={blydeLogo} alt="blyde logo" />
                                <Link to="https://www.blyde.nl/dutch-design-week-2022-greep-uit-onze-highlights" target="_blank">
                                    <button className="btn btn-secondary">{t("c.press.button")}</button>
                                </Link>
                            </li>
                            <li>
                                <img src={npoLogo} alt="npo radio 1 logo" />
                                <Link to="https://www.nporadio1.nl/fragmenten/de-nacht-van/01cbb5c9-a7fc-472e-9c9a-62fe80524df6/2023-09-14-zou-jij-je-privegegevens-inruilen-voor-een-gratis-snoepreep" target="_blank">
                                    <button className="btn btn-secondary">{t("c.press.button")}</button>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}