/*
 * src/components/About/index.js
 */

import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

// Import styling for Tech component
import "./About.css";

// Import other media files for Header component
import tomSrc from "../../assets/img/photo-tom_schouw.jpg";
import sjoerdSrc from "../../assets/img/photo-sjoerd_legue.jpg";

export default function About() {

    // Spawn instance of translation functions
    const { t } = useTranslation();

    return (
        <section id="about">
            <div className="container grid-md">
                <div className="columns">
                    <div className="column type-title">
                        <h2>About</h2>
                        <p>Our vision & skills</p>
                    </div>
                </div>
                <div className="columns type-text">
                    <div className="column">
                        <p>If it isn't interactive, it feels broken. We believe that an interactive installation should be lots of fun from start to end. That's why we try to use and come up with as many immersive game mechanics on all our deeply personal vending machines.</p>
                        <p>We are rapid prototypers with our new generation of DPVM's. This enables us to make your wildest ideas and goals possible.</p>
                    </div>
                    <div className="column">
                        <p>We are a two-man team. There is no smaller unit to call a team, but we make up for size with passion and skill. While we both hold degrees in product design, our expertise is broad. We worked at agencies and startups in the roles of creative, designer, researcher or engineer.</p>
                        <p>Our unity isn't primarily rooted in our love for technology but is better defined by our shared belief in technology's potential to create a more inclusive and empowered society.</p>
                    </div>
                </div>
                <div className="columns type-boxes">
                    <div className="column">
                        <div className="image">
                            <img src={tomSrc} alt="tom schouw interaction designer" />
                        </div>
                        <div className="text">
                            <h3>Tom Schouw</h3>
                            <p className="subtitle">Interaction Designer</p>
                            <p>Tom is a 26-year-old creative advertiser from Rotterdam who specializes in creating playful concepts and catchy formats that communicate complex content to a wide audience. He believes that a creative concept creates a more memorable and impactful experience for consumers.</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="image">
                            <img src={sjoerdSrc} alt="sjoerd legue creative developer" />
                        </div>
                        <div className="text">
                            <h3>Sjoerd Legué</h3>
                            <p className="subtitle">Creative Developer</p>
                            <p>Sjoerd is a 28-year-old full-stack programmer and product designer with 6+ years of experience in custom software and hardware. He's pragmatic, end-user-focused, detail-oriented and has a creative, solution-oriented approach.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}