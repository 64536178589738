/*
 * src/components/Header/index.js
 */

import React from "react";
import { useTranslation } from "react-i18next";

// Import child components for Header
import Navigation from "../Navigation";
import MobileNavigation from "../Navigation/Mobile";

// Import styling for Header component
import "./Header.css";

export default function Header() {

    // Spawn instance of translation functions
    const { t } = useTranslation();

    return (
        <header className="navbar">
            <section className="navbar-section">
                <a className="logo" href={t("app.url")} alt="Deeply Personal Vending Machine website" target="_self">
                    
                </a>
            </section>
            <section className="navbar-center hide-sm">
                <Navigation />
            </section>
            <section className="navbar-section">
                <MobileNavigation />
            </section>
        </header>
    )
}
