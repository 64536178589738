/*
 * src/components/Hero/index.js
 */

import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

// Import styling for Hero component
import "./Hero.css";

import logoSrc from "../../assets/img/dpvm-hero-highres.png";

export default function Hero() {

    // Spawn instance of translation functions
    const { t } = useTranslation();

    return (
        <section id="hero">
            <div className="background">
                <div className="wrap">
                    <div className="ring one"></div>
                    <div className="ring two"></div>
                    <div className="ring three"></div>
                    <div className="machine"></div>
                </div>
            </div>
            <div className="container grid-xl">
                <div className="elements">
                    <div className="title">
                        <h1>The Deeply Personal <br/>Vending Machine</h1>
                    </div>
                    <div className="cta">
                        <Link to="#intro">
                            <button className="btn btn-primary">Discover</button>
                        </Link>
                    </div>
                    <div id="intro" className="text">
                        <p>Promote your brand, gather consumer insights <br/>and give instant rewards</p>
                        <p>With a <br/>Deeply Personal Vending Machine</p>
                        <div className="arrow"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}