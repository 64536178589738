/*
 * src/utils/i18n.js
 */

// Import I18next core modules
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import static files from filesystem
import en_US from "../assets/locales/en_US.json";
import nl_NL from "../assets/locales/nl_NL.json";

// Assemble object with all language files (for langcodes, see http://www.lingoes.net/en/translator/langcode.htm)
const resources = {
    en_US,
    nl_NL
}

// Initialize 'i18n' object with middleware and options
i18n.use(initReactI18next)

    // Initialize 'i18next' with given options (https://www.i18next.com/overview/configuration-options)
    .init({

        // Import locale resource files
        resources: resources,

        // Define core options for 'i18next' module
        lng: "en_US",
        fallbackLng: "en_US",
        preload: ["en_US", "nl_NL"],
        ns: ["DPVM"],
        defaultNS: "DPVM",

        // Define system type options for 'i18next' module
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        // Set all options for 'i18next-http-backend' module (https://github.com/i18next/i18next-http-backend)
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
            useSuspense: true,
            crossDomain: false
        }
    }
);

// Perform export to expose 'i18n' object
export default i18n;
