/*
 * src/containers/DPVM.js
 */

import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

// Import Lodash library utilities
import throttle from "lodash.throttle";

// Import core components for application
import Header from "../components/Header";
import Content from "../components/Content";
import Footer from "../components/Footer";

// Import core styling assets
import "../assets/css/dpvm.css";

function DPVM() {

    // Function to (re)define CSS var(--view-height) variable
    function defViewHeight() {

        // Append CSS variable for correct mobile view height
        let doc = document.documentElement;
        doc.style.setProperty("--view-height", `${window.innerHeight}px`);
    }

    // Handle after-render events for application-wide processes
    useEffect(() => {

        // Define view height immediately after rendering
        defViewHeight();

        // Bind window resize event and redefine var(--view-height)
        window.addEventListener("resize", throttle(defViewHeight, 250));
    });

    // Assemble application core components
    return (
        <Router>
            <div className="container">
                <Header />
                <Content />
                <Footer />
            </div>
        </Router>
    );
}
export default DPVM;
