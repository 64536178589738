/*
 * src/components/Usp/index.js
 */

import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

// Import styling for Usp component
import "./Usp.css";

export default function Usp() {

    // Spawn instance of translation functions
    const { t } = useTranslation();

    return (
        <section id="usp">
            <div className="container grid-lg">
                <div className="columns">
                    <div className="column type-title">
                        <h2>Capabilities</h2>
                        <p>How it works</p>
                    </div>
                </div>
                <div className="columns type-items">
                    <div className="column type-leads">
                        <div className="image"></div>
                        <div className="text">
                            <h3>Gather insights & rewards</h3>
                            <p>Ask your audience anything and turn insights into qualified leads, reward your audience instantly</p>
                        </div>
                        <div className="cta">
                            <Link to="#tech">
                                <button className="btn btn-secondary">Discover</button>
                            </Link>
                        </div>
                    </div>
                    <div className="column type-promote">
                        <div className="image">
                            <div className="side"></div>
                        </div>
                        <div className="text">
                            <h3>Promote your brand</h3>
                            <p>Customise the look and feel with personalised products and an interactive display featuring your logo</p>
                        </div>
                        <div className="cta">
                            <Link to="#tech">
                                <button className="btn btn-secondary">Discover</button>
                            </Link>
                        </div>
                    </div>
                    <div className="column type-style">
                        <div className="image"></div>
                        <div className="text">
                            <h3>Memorable experiences</h3>
                            <p>Fun-filled moments that perfectly align with your brand's identity in a playful and unexpected way</p>
                        </div>
                        <div className="cta">
                            <Link to="#tech">
                                <button className="btn btn-secondary">Discover</button>
                            </Link>
                        </div>
                    </div>
                </div>    
            </div>
        </section>
    )
}