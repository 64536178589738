/*
 * src/components/Tech/index.js
 */

import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

// Import styling for Tech component
import "./Tech.css";

// Import other media files for Header component
import cameraSrc from "../../assets/img/camera-highres.png";
import scaleSrc from "../../assets/img/scale-highres.png";
import heartrateSrc from "../../assets/img/heartrate-highres.png";
import keyboardSrc from "../../assets/img/keyboard-highres.png";
import choiceSrc from "../../assets/img/choice-highres.png";

export default function Tech() {

    // Spawn instance of translation functions
    const { t } = useTranslation();

    return (
        <section id="tech">
            <div className="container grid-md">
                <div className="columns">
                    <div className="column type-title">
                        <h2>Unique features</h2>
                        <p>Our key features <br/><span className="emp">for a deeply personal experience</span></p>
                    </div>
                    <div className="column type-item image-right">
                        <div className="image">
                            <img src={choiceSrc} alt="vending machine multiple choice interface" style={{ transform: 'scale(1.2) translateX(20px)'}} />
                        </div>
                        <div className="text icon-questions">
                            <h3>In need for specific <br/>answers or feedback?</h3>
                            <p>Ask multiple choice questions</p>
                        </div>
                    </div>
                    <div className="column type-item image-left">
                        <div className="image">
                            <img src={cameraSrc} alt="vending machine camera sensor render" />
                        </div>
                        <div className="text icon-scan">
                            <h3>Do you need a way to <br/>pick up appearances?</h3>
                            <p>Scan participants</p>
                        </div>
                    </div>
                    <div className="column type-item image-right">
                        <div className="image">
                            <img src={scaleSrc} alt="vending machine weight scale render" style={{ transform: 'scale(1.2) translateX(10px)'}} />
                        </div>
                        <div className="text icon-balance">
                            <h3>In search for a fun <br/>physical challenge?</h3>
                            <p>Play with the balance board</p>
                        </div>
                    </div>
                    <div className="column type-item image-left">
                        <div className="image">
                            <img src={heartrateSrc} alt="deeply personal vending machine heartbeat sensor" />
                        </div>
                        <div className="text icon-heartrate">
                            <h3>Do you want to measure <br/>excitement levels?</h3>
                            <p>Use the heart-rate sensor</p>
                        </div>
                    </div>
                    <div className="column type-item image-right">
                        <div className="image">
                            <img src={keyboardSrc} alt="personal vending machine display keyboard interface" />
                        </div>
                        <div className="text icon-write">
                            <h3>Or do you want to gather <br/>even more detailed info <br/>such as e-mail addresses?</h3>
                            <p>Use keyboard functionality</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}