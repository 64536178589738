/*
 * src/components/Footer/index.js
 */

import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import child components for Footer
import Language from "../Language";

// Import styling for Footer component
import "./Footer.css";

export default function Footer() {

    // Spawn instance of translation functions
    const { t } = useTranslation();

    return (
        <footer>
            <div id="contact" className="container grid-xl">
                <div className="columns cols-nav">
                    <div className="column col-xyz col-8 col-md-6 col-sm-12">
                        <Link className="xyz" to="/">
                            {/* <div className="image"></div> */}
                        </Link>
                    </div>
                    <div className="column col-2 col-md-3 col-sm-12">
                        <h2>Studio Tom Schouw + <br/>Studio Legué</h2>
                    </div>
                    <div className="column col-2 col-md-3 col-sm-12">
                        <h2>{t("c.contact.title")}</h2>
                        <ul className="contact">
                            <li className="mail"><a href={`mailto:${t("company.email")}`}>{t("company.email")}</a></li>
                            <li className="phone"><a href={`tel:${t("company.phone")}`}>{t("company.phone")}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container grid-terms">
                <div className="container grid-xl">
                    <div className="columns">
                        <div className="column col-8 col-sm-12">
                            <ul className="nav">
                                <li className="nav-item">
                                    <NavLink to="/">
                                        {t("nav.terms")}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/">
                                        {t("nav.cookies")}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/">
                                        {t("nav.privacy")}
                                    </NavLink>
                                </li>
                                <li className="nav-item element-select">
                                    <Language />
                                </li>
                            </ul>
                        </div>
                        <div className="column col-4 col-sm-12">
                            <p>&copy; {new Date().getFullYear()}, {t("company.name")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
