/*
 * src/components/Content/index.js
 */

import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { ReactP5Wrapper } from "react-p5-wrapper";

// Import styling for Content component
import "./Content.css";

// Import P5.js sketch from `assets`
import sketch from "../../assets/sketches/hero.js";

import Hero from "../Hero";
import Usp from "../Usp";
import Tech from "../Tech";
import Press from "../Press";
import About from "../About";
import Products from "../Products";
import Interest from "../Interest/index.js";

export default function Content() {

    // Spawn instance of translation functions
    const { t } = useTranslation();

    return (
        <main id="home" className="content">
            <Hero />
            <Usp />
            <Tech />
            <Products />
            <About />
            <Interest />
            <Press />
        </main>
    )
}
