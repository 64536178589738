/*
 * src/components/Language/index.js
 */

import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function Language() {

    // Spawn instance of translation functions
    const { t, i18n } = useTranslation();

    return (
        <Fragment>
            <select id="lang" value={i18n.language} onChange={(e) => {i18n.changeLanguage(e.target.value)}}>
                <option value="en_US">{t("i18n.en_US")}</option>
                <option value="nl_NL">{t("i18n.nl_NL")}</option>
            </select>
            <label htmlFor="lang"></label>
        </Fragment>
    );
}
