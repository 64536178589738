/*
 * src/index.js
 */

import React from "react";
import ReactDOM from "react-dom/client";

// Import `i18next` module into application
import "./utils/i18n";

// Import main application container
import DPVM from './containers/DPVM';

// Spawn `root` element in document DOM
const root = ReactDOM.createRoot(document.getElementById("root"));

// Render application to `root` element
root.render(
    <DPVM />
);
