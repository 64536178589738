/*
 * src/components/Interest/index.js
 */

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Import styling for Hero component
import "./Interest.css";

export default function Interest() {

    const [formData, setFormData] = useState({
        name: '',
        address: ''
    });

    // Spawn instance of translation functions
    const { t } = useTranslation();

    // Handle form submissions and async send to "mailer" backend
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await fetch('/mailer/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: formData.name,
                    address: formData.address
                })
            });
    
            const data = await response.json();
            if (data.message)
                alert(data.message);

        } catch (error) {
            console.error('Error:', error);
        }
    };

    //
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
    };

    return (
        <section id="interest">
            <div className="container grid-sm">
                <div className="columns">
                    <div className="column type-form">
                        <h2>Let's connect!</h2>
                        <p>Craving more information about our vending machines?<br/>Drop your email, and let's chat.</p>

                        <form autoComplete="on" onSubmit={handleSubmit}>
                            <input name="name" type="text" placeholder="What is your name?" autocomplete="given-name" onChange={handleInputChange}></input>
                            <input name="address" type="email" placeholder="Fill in your e-mail" autocomplete="email" onChange={handleInputChange}></input>
                            <input type="submit" value="Submit"></input>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}