/*
 * src/components/Navigation/index.js
 */

import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

// Import styling for Navigation component
import "./Navigation.css";

export default function Navigation(props) {

    // Spawn instance of translation functions
    const { t } = useTranslation();

    return (
        <nav>
            <ul className="nav">
                {/* <li className="nav-item icon-eye">
                    <Link to="#try" onClick={props.handleClose}>
                        {t("nav.try")}
                    </Link>
                </li>
                <li className="nav-item icon-grid">
                    <Link to="#themes" onClick={props.handleClose}>
                        {t("nav.themes")}
                    </Link>
                </li> */}
                <li className="nav-item icon-data">
                    <Link to="#tech" onClick={props.handleClose}>
                        {t("nav.tech")}
                    </Link>
                </li>
                {/* <li className="nav-item icon-user-circle">
                    <Link to="#about" onClick={props.handleClose}>
                        {t("nav.about")}
                    </Link>
                </li> */}
                <li className="nav-item icon-news">
                    <Link to="#press" onClick={props.handleClose}>
                        {t("nav.press")}
                    </Link>
                </li>
                <li className="nav-item icon-mail" onClick={props.handleClose}>
                    <Link to="#interest">
                        {t("nav.contact")}
                    </Link>
                </li>
            </ul>
        </nav>
    )
}
