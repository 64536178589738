/*
 * src/components/Products/index.js
 */

import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

// Import styling for Tech component
import "./Products.css";

// Product image sources
import apple from '../../assets/img/products/apple.png';
import babybel from '../../assets/img/products/babybel.png';
import charger from '../../assets/img/products/charger.png';
import chocolate from '../../assets/img/products/chocolate.png';
import doritos from '../../assets/img/products/doritos.png';
import figure from '../../assets/img/products/figure.png';
import gummybears from '../../assets/img/products/gummybears.png';
import honey from '../../assets/img/products/honey.png';
import icetea from '../../assets/img/products/icetea.png';
import lolly from '../../assets/img/products/lolly.png';
import mms from '../../assets/img/products/mms.png';
import nailcoat from '../../assets/img/products/nailcoat.png';
import nuts from '../../assets/img/products/nuts.png';
import perfume from '../../assets/img/products/perfume.png';
import skincare from '../../assets/img/products/skincare.png';
import skittles from '../../assets/img/products/skittles.png';
import sunglasses from '../../assets/img/products/sunglasses.png';
import twix from '../../assets/img/products/twix.png';
import vitamins from '../../assets/img/products/vitamins.png';

export default function Products() {

    // Spawn instance of translation functions
    const { t } = useTranslation();

    return (
        <section id="products">
            <div className="background">
                <div className="products">
                    <div className="product size-bigger">
                        <img src={lolly} alt="dpvm product lolly" />
                    </div>
                    <div className="product size-bigger">
                        <img src={mms} alt="personal vending machine product m&ms" />
                    </div>
                    <div className="product">
                        <img src={twix} alt="touchscreen vending machine twix chocolate" />
                    </div>
                    <div className="product">
                        <img src={vitamins} alt="deeply personal vending machine vitamins" />
                    </div>
                    <div className="product">
                        <img src={gummybears} alt="custom vending machine gummy bears" />
                    </div>
                    <div className="product">
                        <img src={skittles} alt="dpvm product skittles" />
                    </div>
                </div>
                <div className="products">
                    <div className="product size-big">
                        <img src={nuts} alt="dpvm product mixed nuts" />
                    </div>
                    <div className="product size-big">
                        <img src={perfume} alt="personal vending machine perfume" />
                    </div>
                    <div className="product size-big">
                        <img src={figure} alt="build interactive vending machine figurine" />
                    </div>
                    <div className="product">
                        <img src={nailcoat} alt="vending machine cosmetics" />
                    </div>
                    <div className="product">
                        <img src={skincare} alt="custom skincare vending machine" />
                    </div>
                    <div className="product">
                        <img src={babybel} alt="dpvm product babybel cheese" />
                    </div>
                    <div className="product size-big">
                        <img src={sunglasses} alt="deeply personal vending machine glasses" />
                    </div>
                </div>
                <div className="products">
                    <div className="product">
                        <img src={doritos} alt="dpvm product doritos" />
                    </div>
                    <div className="product">
                        <img src={charger} alt="vending machine charger product" />
                    </div>
                    <div className="product size-big">
                        <img src={chocolate} alt="tony chocolonely dpvm product" />
                    </div>
                    <div className="product">
                        <img src={honey} alt="deeply personal vending machine honey" />
                    </div>
                    <div className="product">
                        <img src={apple} alt="healthy personal vending machine apple" />
                    </div>
                    <div className="product size-big">
                        <img src={icetea} alt="dpvm product icetea" />
                    </div>
                </div>
            </div>
            <div className="container grid-md">
                <div className="columns">
                    <div className="column type-title">
                        <h2>Products</h2>
                        <p><span className="emp">Feature your product</span> <br/>inside our machine</p>
                    </div>
                </div>
            </div>
        </section>
    )
}